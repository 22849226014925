.main_category {
    width: 100%;
    margin-top: 10px;
    background-color: #fff;
    list-style-type: none;
    margin-bottom: 70px;
    border-radius: 10px;
    overflow: hidden;
    .category {
        padding: 7px 0px 0;
        display: flex;
        align-items: baseline;
        cursor: pointer;
        justify-content: space-between;
        .category-leftside {
            align-items: center;
            width: 100%;
            .category-leftsidetitle {
                padding: 5px 5px 5px 10px;
                font-size: 14px;
                margin: unset;
                color: $primary_color;
                background-color: $shade_background;
            }
            .post-leftsidetitle {
                // padding-left: 40px;
                font-size: 14px;
                margin: unset;
                // padding-top: 10px;
                color: #1b1b1b !important;
                border-bottom: 1px solid rgba(85, 187, 83, .102);
                // padding-bottom: 5px;
                font-weight: 400;
                padding: 10px 0 5px 40px;
            }
        }
    }
}