.business-bg-light{
    @media (min-width: 1200px) {
        .business-about-desc {
            margin-right: 60px;
        }
    }
}
.icon-box-main [class*='col-'] {
    padding: 50px 50px 48px 40px;
    border: 1px solid rgba(0,0,0,.07);
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
}
.icon-box-main [class*='col-']:hover {
    box-shadow: 8px 8px 30px 0 rgba(0,0,0,.12);
    -webkit-box-shadow: 8px 8px 30px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 8px 8px 30px 0 rgba(0,0,0,.12);
    border-color: transparent;
}
.icon-box-main .icon-box .title-box {
    font-size: 21px;
    margin-bottom: 10px;
}