.coporate-form{
    .coporate-form-wrap {
        padding: 65px 70px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        background-color: #fff;
        .hl-text {
            font-weight: 400;
            color: $primary_color;
            font-size: 1.75rem;
        }

    }
}
input[type=text], input[type=email], input[type=password], input[type=number], textarea{
    color: #666666;
    border: 1px solid #dbdbdb;
    padding: 11px 24px;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=tel]:focus,
textarea:focus {
  color: #666666;
}
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #5f5f5f;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: #5f5f5f;
} 
:-ms-input-placeholder {
/* IE 10+ */
color: #5f5f5f;
}
:-moz-placeholder {
/* Firefox 18- */
color: #5f5f5f;
}
.contact-page{
    background-attachment: fixed;
    background-position: 50%;
    background-repeat: no-repeat;
    padding-top: 90px;
    .main-head {
        font-size: 36px;
        margin-bottom: 0;
    }
    .contact-iconb {
        display: flex;
        .icon-main {
            background: rgba(85, 187, 83, .102);
            border-radius: 50% 50% 50% 50%;
            font-size: 27px;
            height: 60px;
            line-height: 60px;
            width: 60px;
            float: left;
            display: inline-block;
            color: #fe8423;
            text-align: center;
            margin-bottom: 17px;
            svg {
                width: 27px;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                fill: #55bb53;
                vertical-align: middle;
            }
        }
        .content-box {
            margin-left: 26px;
            margin-top: 10px;
            max-width: 360px;
        }
        .title-box {
            padding-top: 6px;
            margin-bottom: 1px;
            font-size: 16px;
            font-weight: 600;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
        }
    }
    .icon-box {
        transition: all .3s linear;
        -webkit-transition: all .3s linear;
        p{
            &:last-child{
                margin-bottom: 0;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
            }
        }
    }
}
textarea {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    height: 120px;
}