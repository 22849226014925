.benifit-section{
    .benefits-image {
        width: 100%;
    }
    .icon-box {
        transition: all .3s linear;
        -webkit-transition: all .3s linear;
        &:hover{
            .icon-benifits{
                background-color: $primary_color;
            }
            .icon-image {
                color: #fff;
            }
        }
        .icon-image{
            font-size: 35px;
        }
        .icon-benifits{
            color: $primary_color;
            display: inline-block;
            text-align: center;
            margin-bottom: 17px;
            background: none;
            cursor: pointer;
            float: left;
            line-height: 70px;
            transition: all .3s linear;
            -webkit-transition: all .3s linear;
            border-radius: 5px;
            box-shadow: 0 0 13px 0 rgba(0, 0, 0, .15);
            height: 70px;
            margin-right: 20px;
            width: 70px;
        }
        .content-box {
            font-family: $header-font;
            margin-left: 95px;
            text-align: start !important;
            .title-box {
                font-size: 20px;
                margin-bottom: 15px;
                transition: all .3s linear;
                -webkit-transition: all .3s linear;
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .benifit-section{
      padding-left: 15px;
    }
  }
