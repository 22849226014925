.octf-main-header {
    border: 1px solid red;
    background-color: #fff;
    .octf-mainbar-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        flex: 1 auto;
    }
    .octf-row {
        > div{
            &:empty {
                display: none;
            }
        }
        .octf-col-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: none;
        }
    }
    .main-navigation {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: auto;
        ul {
            list-style: none;
            padding-left: 0;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            font-size: 14px;
            list-style: none;
            margin: 0px 0px;
            padding: 0px 0px;
            position: relative;
            display: flex;
            width: 100%;
            vertical-align: middle;
            height: 100%;
            ul.sub-menu {
                float: none;
                margin: 0;
                padding: 0;
                background: #fff;
                min-width: 250px;
                white-space: nowrap;
                position: absolute;
                top: 100%;
                left: -32px;
                z-index: 10;
                visibility: hidden;
                opacity: 0;
                transform: translateY(30px);
                border-radius: 5px;
                box-sizing: border-box;
                transition: all 0.3s ease-in-out;
          
                // Box-shadow with cross-browser compatibility
                box-shadow: 8px 8px 30px 0px rgba(0, 0, 0, 0.12);
                -webkit-box-shadow: 8px 8px 30px 0px rgba(0, 0, 0, 0.12);
                -moz-box-shadow: 8px 8px 30px 0px rgba(0, 0, 0, 0.12);
          
                // Transform with cross-browser compatibility
                -webkit-transform: translateY(30px);
                -ms-transform: translateY(30px);
          
                // Transition with cross-browser compatibility
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                -ms-transition: all 0.3s ease-in-out;
                &::before{
                    content: "";
                    position: absolute;
                    height: 12px;
                    width: 100%;
                    top: -12px;
                    left: 0;
                }
                &::after{
                    content: "";
                    position: absolute;
                    height: 15px;
                    width: 15px;
                    top: -5px;
                    left: 50px;
                    opacity: 1;
                    z-index: -1;
                    background: #fff;
                    -webkit-border-radius: 2px;
                    -moz-border-radius: 2px;
                    border-radius: 2px;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                ul{
                    &::after{
                        display: none;
                    }
                }
            }
            li {
                margin: 0px 29px;
                padding: 0px 0px;
                float: left;
                position: relative;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                height: 100%;
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 0;
                }
                &::before{
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 3px;
                    background: #fe8423;
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    -ms-transition: all 0.3s ease-in-out;
                }
                &:hover{
                    a{
                        color: #fe8423;
                    }
                    &::before{
                        opacity: 1;
                    }
                }
                a {
                    position: relative;
                    display: inline-block;
                    padding: 10px 0px;
                    line-height: 35px;
                    text-decoration: none;
                    text-align: center;
                    outline: none;
                    color: #1a1b1e;
                    white-space: nowrap;
                    transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    -ms-transition: all 0.3s ease-in-out;
                    &:hover{
                        color: #FE8423;
                    }
                }
                li{
                    display: block;
                    position: relative;
                    a{
                        font-size: 14px;
                        line-height: 30px;
                        color: #1a1b1e;
                        text-align: left;
                        display: block;
                        padding: 8px 30px;
                        position: relative;
                        text-decoration: none;
                        outline: none;
                        border-bottom: 1px solid #f5f5f5;
                        transition: all 0.3s linear;
                        -webkit-transition: all 0.3s linear;
                        -moz-transition: all 0.3s linear;
                        -o-transition: all 0.3s linear;
                        -ms-transition: all 0.3s linear;
                        &:hover{
                            color: #fe8423;
                            background: #f5f5f5;
                        }
                    }

                    &:first-child{
                        a{
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 0;
                            border-bottom-left-radius: 0;
                            -webkit-border-top-left-radius: 5px;
                            -webkit-border-top-right-radius: 5px;
                            -webkit-border-bottom-right-radius: 0;
                            -webkit-border-bottom-left-radius: 0;
                            -moz-border-radius-topleft: 5px;
                            -moz-border-radius-topright: 5px;
                            -moz-border-radius-bottomright: 0;
                            -moz-border-radius-bottomleft: 0;
                        }
                    }
                    &:last-child{
                        a{
                            border: none;
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 5px;
                            border-bottom-left-radius: 5px;
                            -webkit-border-top-left-radius: 0;
                            -webkit-border-top-right-radius: 0;
                            -webkit-border-bottom-right-radius: 5px;
                            -webkit-border-bottom-left-radius: 5px;
                            -moz-border-radius-topleft: 0;
                            -moz-border-radius-topright: 0;
                            -moz-border-radius-bottomright: 5px;
                            -moz-border-radius-bottomleft: 5px;
                        }
                    }
                }
            }
            li.menu-item-has-children{
                a {
                    padding-right: 17px;
                }
                &:last-child{
                    a{
                        &::after{
                            right: 0px;
                        }
                    }
                }
            } 
        }
    }
    .main-style{
        .main-navigation{
            ul{
                li{
                    a{
                        padding-top: 10px;
                        padding-bottom: 10px;

                    }
                }
            }
        }
    }
    .site-logo{
        img{
            height: 43px;
        }
    }
}

.logo-image {
    height: 50px;
}
.header-buttion-signin-up {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .header-button-signin {
        background: #fff;
        border: 1px solid $primary_color;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        margin-right: 10px;
        padding: 5px 15px;
        a{
            color: $primary_color;
            
        }
        &:hover{
            a{
                color: #fff;
            }
            
            background-color: $primary_color;
        }
    }
    .header-button-signup {
        border: 1px solid $primary_color;
        cursor: pointer;
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        padding: 5px 15px;
        color: #fff;
        background: $primary_color;
        border-radius: 5px;
        a{
            color: #fff;
        }
        &:hover{
            color: $primary_color;
            background-color: #fff;
            a{
                color: $primary_color;
            }
        }
    }
    // .header-button-signup {
    //     background: $primary_color;
    //     border-radius: 5px;
    //     a{
    //         color: #fff;
    //     }
    // }
}

.site-header {
    background: #fff;
    position: relative;
    z-index: 10;
    &::before{
        content: "";
        display: table;
        table-layout: fixed;
    }
    &::after{
        content: "";
        display: table;
        table-layout: fixed;
        clear: both;
    }
}



@media only screen and (max-width: 1024px) {
    .header-desktop {
        display: none;
    }
    .header_mobile{
        background: #061E2F;
        display: block;
        width: 100%;
        .mobile_logo {
            padding: 22px 0;
            img {
                width: 140px;
            }
        }
        .octf-btn-cta {
            display: inline-flex;
            vertical-align: middle;
            position: relative;
            .octf-menu-mobile {
                padding-left: 22px;
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
            }
            .mmenu-toggle{
                button{
                    color: #fff;
                    background: none;
                    border: none;
                    outline: none;
                    padding: 0;
                }
                .toggle-bar {
                    font-size: 26px;
                }
            }
        }
        .mmenu-wrapper.mmenu-open {
            -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        .mmenu-wrapper {
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            width: 310px;
            height: 100vh;
            overflow-x: hidden;
            z-index: 9999;
            background: #fff;
            -webkit-transform: translateX(100%);
            -ms-transform: translateX(100%);
            transform: translateX(100%);
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear;
            -o-transition: all 0.3s linear;
            -ms-transition: all 0.3s linear;
            .mmenu-inner {
                top: 0;
                height: 100%;
                width: 327px;
                padding: 20px 52px 35px 35px;
                overflow-x: hidden;
                overflow-y: auto;
                box-sizing: border-box;
            }
            .mmenu-close {
                display: block;
                margin-bottom: 10px;
                font-size: 18px;
                border: none;
                background-color: transparent;
                svg {
                    position: relative;
                    width: 1em;
                    height: 1em;
                }
            }
            .mobile-nav {
                min-width: 240px;
            }
            .mobile_mainmenu {
                margin: 0;
                padding: 0;
                font-family: "Inter", sans-serif;
            }
            .none-style {
                list-style: none;
                padding-left: 0;
            }
            ul {
                list-style: disc;
                li {
                    position: relative;
                    list-style: none;
                    a {
                        padding: 10px 30px 10px 0;
                        display: block;
                        font-size: 14px;
                        font-weight: 500;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        color: #000;
                      }
                }
            }
        }
    }
    
}