.coporate-mkt{
    h1{
        color: #fff;
    }
    .app-section{
        display: flex;
        justify-content: center;
        img{
            cursor: pointer;
        }
    }
    .get-button{
        display: flex;
        justify-content: center;
        button{
            cursor: pointer;
        }
    }
    .started-button, .ios-button{
        margin-right: 10px;
    }
}