.header-main {
    align-items: center;
    background-color: #fff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, .1);
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    .logo-header {
        width: 230px;
    }
    .Header-search-bar {
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
        padding: 5px;
        position: relative;
    }
    .header-cat {
        display: flex;
        flex-direction: row;
        font-weight: 700 !important;
        margin-right: 20px;
    }
    .header-car-content, .headrt-button {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        outline: none;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
    }
    .header-car-content {
        color: #1a1b1e;
        padding: 10px 40px;
    }


    @media only screen and (min-width: 1200px) {
        .Header-search-bar {
            width: 550px;
        }
    }
}
.sidebar-section{
    width: 100%;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    box-shadow: none;
    top: 0;
    // left: 0;
    transition: 350ms;
    z-index: 10;
    .menu-list{
        width: 100%;
        margin-top: 15%;
        background: white;
        color: black;
        height: 100vh;
        overflow-y: scroll;
    }
    .sidebar-top {
        background: #fff;
        color: #000;
        display: flex;
        justify-content: space-between;
        padding: 15px 15px 10px 15px;
        position: absolute;
        width: 100%;
        border-bottom: 1px solid #d4d5d9;
        .title{
            font-family: $header-font;
            
        }
    }
    
}