.finance-banner{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 70px;
    h2{
        color: #000;
        font-family: $header-font;
        font-size: 50px;
        font-weight: 600;
        margin-bottom: 23px;
    }
    p{
        color: #000;
        font-family: $para-font;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 40px;
    }
}
.business-service-section{
    background-color: #F5F5F5;
    &:hover{
        &::after{
            opacity: 0;
        }
        
    }
    .business-icon-service {
        margin-top: -60px;
        &::after{
            background-color: $primary_color;
            position: absolute;
            content: "";
            width: 70px;
            height: 3px;
            bottom: -2px;
            left: 50%;
            margin-left: -35px;
            -webkit-border-radius: 1.5px;
            -moz-border-radius: 1.5px;
            border-radius: 1.5px;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear;
            -o-transition: all 0.3s linear;
            -ms-transition: all 0.3s linear;
        }

    }
    .icon-box-1{
        background-color: #fff;
        overflow: hidden;
        &:hover{
            .icon-main {
                background-color: $primary_color;
                svg {
                    fill: #fff;
                }
            }
            background-color: $primary_color;
            p{
                color: #fff;
            }
        }
        .icon-main {
            background-color: $shade_background;
            display: inline-block;
            color: #fe8423;
            width: 90px;
            height: 90px;
            line-height: 90px;
            text-align: center;
            margin-bottom: 17px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            svg{
                fill: $primary_color;
                width: 44px;
                height: 44px;
                vertical-align: middle;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
            }
        }
    }
    .icon-box{
        &:hover{
            &::before{
                opacity: 1;
            }
            &::after{
                opacity: 0.2;
            }
            .title-box{
                color: #fff;
            }
        }
        div{
            position: relative;
            z-index: 3;
        }
        .title-box{
            font-size: 20px;
            margin-bottom: 15px;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            
            a{
                color: #1b1d21;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
            }
        }
        p{
            &:last-child{
                margin-bottom: 0;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
            }
        }
    }

}
.icon-box-1{
    position: relative;
    padding: 48px 35px 40px;
    box-shadow: 8px 8px 30px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 8px 8px 30px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 8px 8px 30px 0 rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    &::after{
        background-color: #234423;
    }
}
.icon-box{
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    // &::before{
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     content: "";
    //     width: 100%;
    //     height: 100%;
    //     opacity: 0;
    //     transition: all .5s ease;
    //     background-image: linear-gradient(-149deg,#770b0200 0%, #234423 80%);
    //     background-image: -moz-linear-gradient(-149deg,#770b0200 0%, #234423 80%);
    //     background-image: -webkit-linear-gradient(-149deg,#770b0200 0%, #234423 80%);
    // }
    // &::after{
    //     position: absolute;
    //     left: -100px;
    //     top: -120px;
    //     content: "";
    //     width: 230px;
    //     height: 600px;
    //     opacity: 0;
    //     transition: all .5s ease;
    //     background-image: -webkit-linear-gradient(29deg, #fff, #ffffff00);
    //     transform: rotate(35deg);
    // }
}
.feature{
    
    .icon-box {
        height: 171px;
        transition: all .3s linear;
        -webkit-transition: all .3s linear;
        background-color: #fff;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, .12);
        padding: 25px 25px 35px;
        &:hover{
            transform: translateY(-8px);
        }
        .icon-main{
            border-radius: 50%;
            color: $primary_color;
            display: inline-block;
            height: 90px;
            text-align: center;
            width: 90px;
            background: rgba(85, 187, 83, .102);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, .5);
            font-size: 45px;
            line-height: 80px;
            margin-bottom: 17px;
            cursor: pointer;
            float: left;
            transition: all .3s linear;
            -webkit-transition: all .3s linear;
        }
        .content-box {
            font-family: $header-font;
            margin-left: 95px;
            text-align: start;
            .title-box {
                font-weight: 600;
                margin-bottom: 10px;
                font-size: 20px;
                transition: all .3s linear;
                -webkit-transition: all .3s linear;
                padding-top: 11px;
            }
            p{
                font-family: $header-font;
            }
        }
    }

}



@media only screen and (max-width: 768px) {
    .finance-banner{
        margin-top: unset;
        h2{
            font-size: 40px;
            line-height: 1.3;
            height: 215px;
        }
    }
    .feature {
        margin-top: 25px;
    }
}
@media only screen and (min-width: 991px) {
    .feature {
        margin-top: -65px;
        position: relative;
    }
}