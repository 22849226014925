.pricing-table-section {
    background-position: top;
    background-repeat: no-repeat;
    p {
        margin: 0 0 20px;
        font-family: $header-font;
    }
    .ot-heading{
        .is_highlight{
            background: rgba(85, 187, 83, .102);
            border: 1px solid $primary_color;
            border-radius: 15px;
            padding: 2px 10px;
        }
        span{
            color: $primary_color;
            display: inline-block;
            font-size: 12px;
            font-weight: 700;
            margin-bottom: 10px;
            position: relative;
            text-transform: uppercase;
        }
        .main-head {
            margin-bottom: 0;
            font-weight: 700;
        }
    }
    .ot-switcher {
        text-align: center;
        input:checked + .slider:before {
            -webkit-transform: translateX(29px);
            -ms-transform: translateX(29px);
            transform: translateX(29px);
        }
        span {
            font-size: 14px;
            font-weight: 600;
            vertical-align: middle;
        }
        .switch {
            display: inline-block;
            height: 34px;
            margin: 0 15px;
            position: relative;
            width: 66px;
            input{
                opacity: 0;
                width: 0;
                height: 0;
            }
        }
        .slider.round {
            border-radius: 34px;
            &::before{
                border-radius: 50%;
            }
        }
        .slider{
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.2);
            -webkit-transition: 0.4s;
            transition: 0.4s;
            &::before{
                background-color: #fff;
                bottom: 4px;
                content: "";
                height: 26px;
                left: 5px;
                position: absolute;
                transition: .4s;
                width: 26px;
            }
        }
    }
    .ot-pricing-table{
        border-radius: 5px;
        -webkit-border-radius: 5px;
        box-shadow: 8px 8px 30px 0 rgba(0, 0, 0, .12);
        -webkit-box-shadow: 8px 8px 30px 0 rgba(0, 0, 0, .12);
        overflow: hidden;
        position: relative;
        text-align: center;
        transition: all .3s linear;
        -webkit-transition: all .3s linear;
        .inner-table {
            background: #fff;
            padding: 30px 22px 40px;
            .title-table {
                font-size: 20px;
                margin-bottom: 35px;
                span {
                    background: #262832;
                    border-radius: 14px;
                    color: #fff;
                    display: inline-block;
                    font-size: 13px;
                    line-height: 1;
                    margin-bottom: 0;
                    padding: 7px 11px;
                    text-transform: uppercase;
                } 
                
                
            }
            .amt {
                color: #569631;
                display: inline-block;
                font-size: 30px;
                font-weight: 600;
                line-height: 1;
                padding-left: 27px;
                position: relative;
                top: -1px;
            }
            sup {
                font-size: 24px;
                left: 8px;
                position: absolute;
                top: 15px;
            }  
            h6{
                bottom: -9px;
                color: #959595;
                display: inline-block;
                margin-bottom: 40px;
                padding-left: 15px;
                position: relative;
                &::before{
                    background: #959595;
                    content: "";
                    height: 49px;
                    left: 5px;
                    position: absolute;
                    top: -15px;
                    -webkit-transform: rotate(40deg);
                    transform: rotate(40deg);
                    width: 1px;
                }
            }   
            .short-text {
                font-size: 14px;
                margin-bottom: 32px;
            }          
            .details {
                border-top: 1px solid rgba(0, 0, 0, .15);
                padding: 26px 0;
                ul {
                    line-height: 42px;
                    list-style: none;
                    margin-bottom: 0;
                    padding: 0;
                    text-align: left;
                    li.active {
                        position: relative;
                    }
                    li{
                        &::before{
                            color: $primary_color;
                            content: "✓";
                            font-weight: 800;
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }
}