.cta{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    h1{
        color: #fff;
    }
    .app-section {
        display: flex;
        justify-content: center;
        img {
            cursor: pointer;
            width: 15%;
        }
        .ios-button{
            margin-right: 10px;
        }
    }
    @media only screen and (max-width: 991px) {
        .app-section {
            img{
                width: 30%;
            }
        }
    }
}


//Faq
.accordian-data-div {
    border: none;
    border-radius: 5px;
}
.accordion-header {
    border: 1px solid #d3d3d3 !important;
    margin: 10px;
    button {
        font-weight: 600;
    }
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.accordion-button {
    background-color: #f7f7f8 !important;
    box-shadow: none !important;
}

//subscription
.ot-pricing-table {
    .octf-btn{
        border-color: $primary_color;
        background-color: #fff !important;
        a{
            color: $primary_color;
        }
        &:hover{
            background-color: $primary_color !important;
            a{
                color: #fff;
            }
        }
    }
}