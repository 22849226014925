.search {
    border-bottom: 1px solid #eee;
    display: flex;
    position: relative;
    padding-top: 7px;
    padding-bottom: 7px;

    .search-left-icon {
        margin-left: 16px;
        margin-top: 13px;
        margin-right: 10px;
    }

    .custom-search-input {
        width: 100%;
        border: none;
        outline: none;
        padding: 12px 12px 12px 0px;
    }

    .custom-search-input::placeholder {
        font-size: 12px;
    }

    .custom-search-inputicon {
        color: $primary_color;
        cursor: pointer;
        margin-top: 13px;
        margin-right: 16px;
    }
}

.listElements {
    padding: 8px 16px;

    .listElement-title {
        font-size: 12px;
    }

    ul {
        list-style-type: none;
        padding: unset;


        li {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            display: inline-block;
            font-size: 12px;
            margin: 3px;
            letter-spacing: -0.005em;
            padding: 4px 12px !important;
            box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, .25882);

            a {
                text-decoration: none;
            }
        }

    }
}

.searchDetail {
    width: 100%;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 1px 4px #0000001f;
    height: 382px;
    overflow-y: scroll;
    padding: 10px;
    position: absolute;
    // width: 650px;
}

@media screen and (max-width: 991px) {
    .searchDetail {
        border: none;
        box-shadow: none;
        height: auto;
        margin-top: 0;
        width: 100%;
        z-index: 9999;
    }

    .searchDetail .searchbar-product-list {
        border-bottom: 1px solid #eee;
        padding-bottom: 5px;
        padding-top: 5px;
    }
}



.searchDetail .searchbar-product-list {
    align-items: center;
    color: #000;
    cursor: pointer;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
        padding-bottom: 5px;
}

.searchbar-product-list>div>p {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 14px;
    overflow: hidden;
    margin-bottom: 5px;
    color: rgb(94, 110, 135);
}

.search-title-products {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 4px;
}



.searchDetail::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  .searchDetail::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .searchDetail::-webkit-scrollbar-thumb {
    background: $primary_color;
  }