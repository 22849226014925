.site-footer{
    background-color: #061E2F;
    color: #AFC1CE;
    &::before{
        content: "";
        display: table;
        table-layout: fixed;
    }
    &::after{
        clear: both;
        content: "";
        display: table;
        table-layout: fixed;
    }
    &:last-child{
        margin-bottom: 0;
    }
    .widget-footer-title {
        margin-bottom: 28px;
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: $header-font;
        line-height: 1.2;
    }
    .footer-contact {
        position: relative;
        width: auto;
        display: flex;
        margin-bottom: 16px;
        svg{
            position: relative;
            display: block;
            width: 1em;
            height: 1em;
        }
        .footer-contact-icon {
            fill: $primary_color;
            color: $primary_color;
            border-color: $primary_color;
            font-size: 22px;
            margin-right: 20px;
            svg{
                fill: $primary_color;
            }
        }
        .footer-contact-desc {
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
        }
        h6{
            font-family: $para-font;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
            color: #fff;
            a{
                font-family: $para-font;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 5px;
                color: #fff;
                &:hover{
                    color: $primary_color;
                }
            }
        }
        p{
            color: #C0C1C2;
            font-family: "Inter", Sans-serif;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
    .copyright-text {
        color: #879DAD;
        font-size: 15px;
        font-family: $para-font;
        border-top: 1px solid rgba(255, 255, 255, .1);
        padding: 25px 0 25px 0;
        margin-bottom: 0;
        text-align: center;
    }
    

    @media (min-width: 768px) {
        .footer-contact-icon {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
        }
        .footer-contact-desc {
            text-align: left;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }
    }
}
#exampleModal{
    .modal-header{
      border-bottom: unset;
    }
    .coporate-form-wrap{
      box-shadow: unset;
      padding: unset;
    }
}