a{
    text-decoration: none;
}
.error{
    color: red;
}
.space-5{
    width: 100%;
    height: 5px;
}
.space-6{
    width: 100%;
    height: 6px;
}
.space-7{
    width: 100%;
    height: 7px;
}
.space-10{
    width: 100%;
    height: 10px;
}
.space-20{
    width: 100%;
    height: 20px;
}
.space-30{
    width: 100%;
    height: 30px;
}
.space-40{
    width: 100%;
    height: 40px;
}
.space-60{
    width: 100%;
    height: 60px;
}
.space-70{
    width: 100%;
    height: 70px;
}
.space-80 {
    width: 100%;
    height: 80px;
}
.space-90{
    width: 100%;
    height: 90px;
}
.space-120{
    width: 100%;
    height: 120px;
}
.rounded-img {
    border-radius: 5px;
}
img {
    height: auto;
    max-width: 100%;
}
h1, h2, h3, h4, h5, h6 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 20px;
    color: #1b1d21;
}
a {
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    color: #1b1d21;
    text-decoration: none;
  }
  a:hover, a:focus, a:active {
    // color: $primary_color;
    text-decoration: none;
  }
  a:focus {
    outline: 0;
  }
  a:hover, a:active {
    outline: 0;
  }
p {
    margin: 0 0 20px;
}
.ot-heading {
    font-family: $header-font;
    span {
        color: #95A2AC;
        border-color: #95A2AC;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 6px;
    }
    .main-head {
        margin-bottom: 0;
        font-size: 36px;
        font-family: $header-font;
        font-weight: 600;
    }
}
.octf-btn {
    background-color: $primary_color;
    border-color: $primary_color;
    color: #fff !important;

    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    font-size: 14px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 14px 30px;
    line-height: 1.42857143;
    display: inline-block;
    margin-bottom: 0;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    vertical-align: middle;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    text-align: center;
    background: $primary_color;
    cursor: pointer;
    border: 1px solid transparent;
    color: #fff;
    outline: none;
    position: relative;
    &:hover{
        background: #fff;
        border-color: $primary_color;
        color: $primary_color !important;
    }
    &:focus{
        background: #fff;
        border-color: $primary_color;
        color: $primary_color;
    }
}
.preloader{
    .preloader-div{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    @media only screen and (max-width: 768px) {
      .preloader-div{
        left: 60%;
      }
    }
}
