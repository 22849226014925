.doc-div{
    margin-top: 70px;
    .document-homepage {
        padding-top: 3%;
        h1, h6{
            padding-bottom: 3%;
        }
        h6{
            font-weight: 400;
            line-height: 1.5;
        }
        .category-section {
            padding: 10%;
            border-radius: 5px;
            background-color: rgba(85, 187, 83, 0.1019607843);
            height: 320px;
            h2 {
                color: $primary_color !important;
                padding-bottom: 2%;
            }
        }
    }
}
.document-detail{
    padding: 10px 35px;
}
.docs_review_border {
    border-bottom: 2px solid #d4d5d9;
    border-top: 2px solid #d4d5d9;
    padding: 30px;
   .docs_review {
        display: flex;
        flex-wrap: nowrap;
        gap: 0 24px;
        justify-content: flex-start;
        justify-content: center;
        p{
            color: #132644;
            font-family: $header-font;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
            margin: 0;
            padding: 0;
        }
        .docs_review_btn_green {
            flex-wrap: nowrap;
            gap: 0 8px;
            justify-content: flex-start;
            .a{
                
                background-color: rgba(0, 158, 92, .09);
                border: 1px solid rgba(0, 158, 92, .32);
                border-radius: 0;
                cursor: pointer;
                padding: 4px 20px;
               
            }
            .b{
                background-color: rgba(245, 71, 41, .09);
                border: 1px solid rgba(245, 71, 41, .32);
                border-radius: 0;
                cursor: pointer;
                padding: 4px 20px;
            }
        }
    }
}
.cartside-bar-main{
    height: 100vh;
    overflow-y: scroll;
    .catsidebar-cattitle {
        background-color: rgba(85, 187, 83, .102);
        color: $primary_color;
        font-weight: 700;
        padding: 2px 5px 2px 10px;
        cursor: pointer;
    }
    .document-subcategory {
        padding: 2px 0 20px;
        .subcategorydata {
            border-bottom: 1px solid rgba(85, 187, 83, .102);
            color: #1b1b1b;
            font-size: 15px;
            padding: 3px 3px 3px 20px;
        }
    }
}
@media only screen and (min-width: 991px) {
    .cartside-bar-main {
        background: #fff;
        left: 0;
        position: fixed;
        width: 250px;
    }
}
@media only screen and (max-width: 991px) {
    .doc-div{
        margin-top: 60px;
    }
    .document-detail {
        padding: 43px 35px;
    }
    .docs_review {
        display: block !important;
    }
}