.mobile-container {
    padding: 35px; 
    background: linear-gradient(to right, #9cd5d2cf,#e0ebe9 , #FFBBBB);
}
.detail-body{
    ul {
        list-style: none;
    }
    
    ul li:before {
        content: '✓'; 
        color: $primary_color; 
        font-weight: 800; 
        margin-right:8px ;
    } 
    .ul-container{
        background-color: $shade_background; 
        padding-top: 12px; 
        padding-bottom: 12px; 
        border-radius: 12px; 
    }
}
.main-container{
    .service4-top-detail3 {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 10%;
        h6{
            color: #fff !important;
            // font-family: Inter, Sans-serif;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.2;
            margin: 0 0 20px;
        }
        h2{
            color: #fff !important;
            // font-family: Inter, sans-serif;
            font-size: 36px;
            font-weight: 700;
            line-height: 1.2;
        }
    }
    .ot-button {
        margin-right: 40px;
        .octf-btn {
            background-color: $primary_color !important;
            border-color: $primary_color;
            color: #fff !important ;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            font-size: 14px;
            border-radius: 3px;
            padding: 14px 30px;
            line-height: 1.42857143;
            display: inline-block;
            margin-bottom: 0;
            text-transform: uppercase;
            white-space: nowrap;
            vertical-align: middle;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            border: 1px solid transparent;
            position: relative;
            text-decoration: none;
            &:hover{
                background: #fff !important;
                border-color: $primary_color;
                color: $primary_color !important;
            }
        }
        .octf-btn-light {
            background: #fff;
        }
    }
}

.service-widget-area{
    .service-widget{
        margin-bottom: 45px;
        .service-widget-title{
            padding-bottom: 18px;
            margin-bottom: 35px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            position: relative;
            font-weight: 700;
            line-height: 1.2;
            color: #1b1d21;
            &::after{
                content: "";
                position: absolute;
                right: 0;
                bottom: -2px;
                width: 57px;
                height: 3px;
                background: #fff;
                z-index: 1;
            }
            &::before{
                content: "";
                position: absolute;
                right: 0;
                bottom: -2px;
                width: 40px;
                height: 3px;
                background: $primary_color;
                z-index: 2;
            }
        }
        ul{
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            &:not(.recent-news) {
                > li {
                  font-size: 14px;
                  position: relative;
                  margin-bottom: 11px;
                  line-height: 24px;
                  
                  a{
                    font-weight: 600;
                    color: #1b1d21;
                    display: inline-block;
                    position: relative;
                    transition: all 0.35s linear;
                    -webkit-transition: all 0.35s linear;
                    text-transform: uppercase;
                    padding-left: 15px;
                    font-size: 13px;
                    text-decoration: none;
                    &:hover{
                        color: $primary_color;
                      }
                  }
                }
            }
            li{
                a{
                    &::before{
                        content: "";
                        position: absolute;
                        left: 0;
                        top: calc(50% - 2.5px);
                        width: 5px;
                        height: 5px;
                        background: $primary_color;
                        transition: all 0.3s linear;
                        -webkit-transition: all 0.3s linear;
                    }
                }
            }
        }
        .widget-service-icon svg {
            fill: $primary_color;
            width: 18px;
            height: 18px;
            margin-right: 20px;
        }
        .widget-service-text{
            h6 {
                font-family: "Inter", Sans-serif;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 5px;
            }
            p {
                color: #5F5F5F;
                font-family: "Open Sans", Sans-serif;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0;
            }
        } 
    }
}
.main-heading {
    color: black;
    font-size: 50px;
    font-weight: 800;
}
.main-heading-sub {
    color: black;
    font-size: 40px;
    font-weight: 700;

}
.headings {
    font-size: 26px;
    font-weight: 700;
}
.descriptions {
    font-size: 16px;
    text-align: left;
    color: rgb(39, 39, 39);
}

.best-words {
    color: $primary_color;
    font-size: 26px;
    font-weight: 700;
}
.sub-container {
    background-color: $shade_background; 
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 12px;
}
.speacial-words {
    text-decoration: underline;
    text-decoration-color: $primary_color;
    /* font-weight: 700;  */
    font-style: italic;
}
.first-letter::first-letter {
    font-family: 'Slabo 20px';
    -webkit-initial-letter: 2;
    initial-letter: 2;
    color: $primary_color;
    font-weight: bold;
    margin-right: .75em;
}
.main-text-container {
    display: flex;
    padding: 0;
}
.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.text-indent{
    text-indent: 100px;
}
.indent{
    text-indent: 80px;
}

.site-content{
    &::before{
        content: "";
        display: table;
        table-layout: fixed;
    }
    &::after{
        content: "";
        display: table;
        table-layout: fixed;
    }
    .page-header-service {
        // background-image: url("https://admin.shopersbay.com/assets/uploads/10015/ckeditor/91d0d273dfd9611e4f34a7788c545780.jpg");
        // background-image: url("../../images/2148854506.jpg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover; 
    }
    .page-header {
        width: 100%;
        height: 400px;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        background-size: auto;
        .inner{
            margin-top: -13px;
        }
        .page-title {
            font-size: 42px;
            color: #fff;
            margin-bottom: 0;
            flex: 1;
            padding: 10px 0;
        }
        .h1{
            color: #1b1d21;
            font-family: Inter, sans-serif;
            font-weight: 700;
            line-height: 1.2;
            margin: 0 0 20px;
        }
        .breadcrumbs {
            margin-bottom: 0;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
            li {
                display: inline-block;
                color: #fff;
                a {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
            svg {
                font-size: 22px;
                color: rgba(255, 255, 255, 0.6);
            }
        }
        .none-style {
            list-style: none;
            padding-left: 0;
        }
    }
    .flex-middle {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
#content{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.service4-top-detail3 {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 0 70px 100px;
    h6 {
        color: #fff;
        font-family: Inter, Sans-serif;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }
    h2 {
        color: #fff;
        font-family: Inter, sans-serif;
        font-size: 36px;
        font-weight: 700;
        line-height: 1.2;
    }
}
.service-detail-quote {
    border-radius: 5px;
    padding: 50px 10px;
    .service-detail-quote-desc {
        border: solid $primary_color;
        border-width: 0 0 0 5px;
        display: flex;
        justify-content: space-between;
        padding: 28px 0 25px 50px;
        h3 {
            color: #fff;
            margin-bottom: 2px;
        }
    }
}



@media screen and (min-width:992px) {
    .left {
        margin-left: 50px;
    }

    .right {
        margin-right: 50px;
    }
    .image{
        width: 70%;
    }
}
@media (max-width: 768px) {
    .page-header {
        .inner {
            display: block;
        }
        .page-title {
            font-size: 30px !important;
            margin-bottom: 10px !important;
        }
    }
}

